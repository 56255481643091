@import "../../../bemit/bemit-settings-and-tools.scss";

.m-close-button{
    
    $b : &;
    
    @include rp-prepare-debug();

    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-end;

    &__button{

        width: 20px;
        display: block;
        height: auto;

    }

    &__icon{

        fill: var(--current-primary-color);
        transition: all 0.3s ease-in-out;

        &:hover{

            fill: var(--color-black);

        }
        
    }

}
