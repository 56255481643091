@import "../../bemit/bemit-settings-and-tools.scss";

.c-featured{
    
    $b : &;
    
    @include rp-prepare-debug();

    display: block;
    padding-bottom: 28px;

    &__item{

        position: relative;
        width: 100%;
        display: block;

    }

    &__link{

        position: relative;
        width: auto;
        display: inline-block;

    }

    &__wrapper-image{

        display: block;
        width: 100%;
        margin-bottom: 16px;

    }

    &__image{

        display: block;
        width: 100%;
        height: auto;

    }

    &__wrapper-title{

        display: flex;
        width: 100%;
        padding-bottom: 10px;

    }

    &__title-link{

        display: inline-block;
        width: 90%;
        color: $black;
        transition: all 0.3s ease-in-out;

        &:hover{

            color: var(--current-primary-color);

        }

        @include media-max($break-desktop){
    
            width: 100%;
    
        }

    }

    &__title{

        $current_font: (
            md: (
                font-size       : 48px,
                line-height     : 58px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            sm: (
                font-size       : 48px,
                line-height     : 58px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            xs: (
                font-size       : 48px,
                line-height     : 58px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        display: block;
        width: 100%;
        color: inherit

    }

    &__wrapper-info{

        width: 100%;
        display: flex;
        align-items: flex-start;

    }

    &__info-text{

        $current-font: (
            md: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__bold),
                font-weight     : normal
            ),
            sm: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__bold),
                font-weight     : normal
            ),
            xs: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__bold),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        color: var(--current-primary-color);
        text-transform: uppercase;

        &-highlight{

            $current-font: (
                md: (
                    font-size       : 14px,
                    line-height     : 18px,
                    letter-spacing  : 0.5px,
                    font-family     : var(--font-b__regular),
                    font-weight     : normal
                ),
                sm: (
                    font-size       : 14px,
                    line-height     : 18px,
                    letter-spacing  : 0.5px,
                    font-family     : var(--font-b__regular),
                    font-weight     : normal
                ),
                xs: (
                    font-size       : 14px,
                    line-height     : 18px,
                    letter-spacing  : 0.5px,
                    font-family     : var(--font-b__regular),
                    font-weight     : normal
                ),
            );

            @include rp-font($current-font);

            background-color: var(--current-primary-color);
            color: var(--color-white);

        }

    }

    &__info-sep{

        $current-font: (
            md: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            sm: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            xs: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        color: var(--current-primary-color);
        padding-left: 4px;

    }

}
