@import "../../bemit/bemit-settings-and-tools.scss";


.b-share{

    position: relative;
    // display: none; // ! TEMP

    &.in-menu{

        position: absolute;
        bottom: 32px;
        left: calc( ( ( (var(--menu-item-children-active) - 1) * var(--menu-item-w) ) + (var(--menu-item-w-first) ) ) - (var(--menu-item-w)/2));
        transform: translateX(-50%);
        z-index: 999;
        transition: all 0.2s ease-in-out, opacity 0.3s ease-in-out 0.3s, visibility 0.3s ease-in-out 0.3s;
        opacity: 1;
        visibility: visible;

        .is-changing &{

            opacity: 0;
            visibility: hidden;
            transition: opacity 0s ease-in-out 0s, visibility 0s ease-in-out 0s;

        }

        @include media-max($break-desktop){

            position: relative;
            bottom: 0px;
            left: 0px;
            transform: none;
            display: none;

        }

    }

    &.in-footer{

        display: none;

        @include media-max($break-desktop){

            position: relative;
            bottom: 0px;
            left: 0px;
            transform: none;
            display: block;
            // margin-top: 20px;

        }

    }

    &.is-big{

        margin-top: 4px;

    }

    .b-header__menu-item:first-child.is-active ~ &.in-menu,
    .is-detail &.in-menu{

        opacity: 0;
        visibility: hidden;
        transition: 0s;

    }

    &__button{

        background-color: var(--current-primary-color);
        border-radius: 7px;
        position: relative;
        z-index: 2;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        .is-big &{

            width: 140px;
            padding-left: 13px;
            background-color: #DADADA;
            height: 34px;
            justify-content: flex-start;

            *{

                fill: var(--color-black);

            }

        }

    }

    &__button:hover + &__items,
    &__button:focus + &__items,
    &__button:active + &__items{

        opacity: 1;
        visibility: visible;

    }

    &__items{

        position: absolute;
        bottom: 0px;
        background-color: var(--current-primary-color);
        width: auto;
        padding-bottom: 32px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        left: 0px;
        border-radius: 10px;
        padding-top: 0px;
        overflow: hidden;

        .is-big &{

            left: auto;
            right: 0px;
            width: 100%;
            background-color: #DADADA;
            padding-top: 34px;
            padding-bottom: 0px;
            bottom: auto;
            top: 0px;
            transition: all 0.16s ease-in-out;

        }

        button{

            width: 100%;
            display: flex;
            align-items: center;
            padding: 1px 1px!important;

            .is-big &{

                padding-left: 6px!important;

            }

            svg{

                circle{

                    fill: transparent!important;

                }

                *{
                
                    fill: var(--color-white);
    
                }

                .is-big & *{
                
                    fill: var(--color-black);
    
                }
                
            }

            &:hover{

                background-color: #02FFA6!important;

            }

            .is-big &:hover{

                background-color: #02FFA6!important;

                *{
                
                    // fill: var(--color-white);
    
                }

                .b-share__label-icon{

                    // color: var(--color-black);

                }

            }

        }

        &:hover,
        &:focus,
        &:active{

            opacity: 1;
            visibility: visible;

        }

    }

    &__text{

        $current-font: (
            md: (
                font-size       : 12px,
                line-height     : 15px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            sm: (
                font-size       : 12px,
                line-height     : 15px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            xs: (
                font-size       : 12px,
                line-height     : 15px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        color: var(--color-white);
        text-transform: uppercase;
        padding-left: 16px;

    }

    &__label-share{

        $current-font: (
            md: (
                font-size       : 12px,
                line-height     : 15px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            sm: (
                font-size       : 12px,
                line-height     : 15px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            xs: (
                font-size       : 12px,
                line-height     : 15px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        padding-left: 15px;
        padding-right: 10px;
        text-transform: uppercase;
        color: var(--color-black);

    }

    &__label-icon{

        $current-font: (
            md: (
                font-size       : 12px,
                line-height     : 15px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            sm: (
                font-size       : 12px,
                line-height     : 15px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            xs: (
                font-size       : 12px,
                line-height     : 15px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        padding-left: 9px;
        padding-right: 14px;
        text-transform: uppercase;
        color: var(--color-black);

    }

}
