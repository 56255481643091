@font-face {
  font-family: "BridgeHeadCon-Black";
  src: url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Con-Black.eot");
  src: url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Con-Black.eot?#iefix")
      format("embedded-opentype"),
    url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Con-Black.woff2")
      format("woff2"),
    url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Con-Black.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BridgeHeadExt-Medium";
  src: url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Ext-Medium.eot");
  src: url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Ext-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Ext-Medium.woff2")
      format("woff2"),
    url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Ext-Medium.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BridgeHeadExt-Bold";
  src: url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Ext-Bold.eot");
  src: url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Ext-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Ext-Bold.woff2")
      format("woff2"),
    url("BridgeHead-TypeMates/Webfonts/Bridge-Head-Ext-Bold.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
