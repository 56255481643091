@import "../../bemit/bemit-settings-and-tools.scss";

.b-debug-columns{

    opacity: 0;
    display: block;
    pointer-events: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 9999999;
    background: none;

    .is-debug &{

        opacity: 0.2;

    }

    &__col{

        height: 100vh;
        display: inline-block;

    }

    &__col-content{

        background-color: rgba(lightgreen, 0.4);
        width: 100%;
        height: 100%;
        display: block;

    }

}
