@import "../../bemit/bemit-settings-and-tools.scss";


.b-access{

    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    left: 0px;
    top: 0px;
    z-index: 999999995;
    background-color: var(--color-black);

    &::before{

        content: "";
        // background-color: rgba($black, 0.3);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        z-index: 2;

    }

    &__wrapper{

        padding: 8px;
        position: relative;
        display: block;
        color: $white;
        z-index: 3;
        
    }

    &__wrapper-date{

        width: 100%;
        display: block;

    }

    &__date{

        $current_font: (
            md: (
                font-size       : 18px,
                line-height     : 25px,
                letter-spacing  : 1px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 12px,
                line-height     : 16px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 12px,
                line-height     : 16px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        width: 100%;
        display: block;
        color: var(--color-white);
        text-transform: uppercase;
        padding-left: 20px;
        text-align: center;

    }

    &__wrapper-title{

        width: 100%;
        display: block;

    }

    &__title{

        $current_font: (
            md: (
                font-size       : 140px,
                line-height     : 169px,
                letter-spacing  : -1.75px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            sm: (
                font-size       : 60px,
                line-height     : 89px,
                letter-spacing  : -1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            xs: (
                font-size       : 45px,
                line-height     : 67px,
                letter-spacing  : -1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            )
        );

        @include rp-font($current_font);

        width: 100%;
        display: block;
        color: var(--color-white);
        text-align: center;

    }

    &__wrapper-caption{

        width: 100%;
        display: block;

    }

    &__caption{

        $current_font: (
            md: (
                font-size       : 18px,
                line-height     : 25px,
                letter-spacing  : 1px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 12px,
                line-height     : 16px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 12px,
                line-height     : 16px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        width: 100%;
        display: block;
        color: var(--color-white);
        text-transform: uppercase;
        padding-left: 20px;
        text-align: center;

    }

    &__wrapper-button{

        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 70px;

    }

    &__button{

        $current_font: (
            md: (
                font-size       : 18px,
                line-height     : 22px,
                letter-spacing  : 0.82px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 16px,
                line-height     : 21px,
                letter-spacing  : 0.82px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 16px,
                line-height     : 21px,
                letter-spacing  : 0.82px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        width: auto;
        display: inline-block;
        color: var(--color-white);
        text-transform: uppercase;
        padding: 16px 42px;
        border-radius: 20px;
        border: 1px solid var(--color-white);
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover{

            background-color: var(--color-white);
            color: var(--color-black);

        }

    }

    &__block-partner{

        position: absolute;
        left: 0px;
        bottom: 40px;
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        z-index: 4;

    }

    &__wrapper-bg{

        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: block;

    }

    &__bg-image{

        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;

    }
    
}
