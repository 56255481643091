@import "../../bemit/bemit-settings-and-tools.scss";

.c-map-render{
    
    $b : &;
    
    @include rp-prepare-debug();

    padding: 20px 0px;
    padding-bottom: 0px;
    display: block;

    &__wrapper{

        display: block;
        width: 100%;
        height: calc(100vh - 166px);

        svg{

            width: auto;
            display: block;
            margin-left: auto;
            height: 100%;
            max-width: 100%;

        }

    }

    &__svg{

        width: 100%;
        height: auto;
        stroke: var(--color-white);
        stroke-width: 0.05;
        // stroke-linecap: round;
        // stroke-linejoin: round;

        &-location {

            fill: rgba($color-e, 0.5);
            cursor: pointer;
            &:hover,
            &:focus{

                fill: $color-e;
                outline: 0;

            }

            &[aria-checked=true]{

                fill: $color-e;
                
            }

            &[id="ES"]{

                stroke-width: 0;

            }

            &.is-inactive{
                fill: rgba($color-e, 0.1);
                cursor: default;
            }

        }

        &-tooltip {

            $current-font: (
                md: (
                    font-size       : 12px,
                    line-height     : 15px,
                    letter-spacing  : 0.5px,
                    font-family     : var(--font-a__bold),
                    font-weight     : normal
                ),
                sm: (
                    font-size       : 12px,
                    line-height     : 15px,
                    letter-spacing  : 0.5px,
                    font-family     : var(--font-a__bold),
                    font-weight     : normal
                ),
                xs: (
                    font-size       : 12px,
                    line-height     : 15px,
                    letter-spacing  : 0.5px,
                    font-family     : var(--font-a__bold),
                    font-weight     : normal
                ),
            );

            @include rp-font($current-font);

            position: fixed;
            width: 140px;
            padding: 8px;
            border: 1px solid var(--color-white);
            background-color:var(--color-e);
            color: var(--color-white);
            text-align: center;
            border-radius: 8px;
            text-transform: uppercase;
    
        }

    }

}
