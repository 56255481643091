
// =========================================================================================
// VARS
// =========================================================================================


// DEBUG
$debug : false;


// PALETTE

    // THEME

        $color-a: #DD5900;
            $color-a-alt: #FF7238;

        $color-b: #C69839;
            $color-b-alt: #DCB400;

        $color-c: #00248A;
            $color-c-alt: #6496C8;

        $color-d: #004B32;
            $color-d-alt: #009060;

        $color-e: #F60D49;
            $color-e-alt: #E5636F;

        // GENERAL

        $black: #281E1E;
        $white: #F4F2EE;


    // FALLBACKS




// TRANSITION BASE RP
$rp_transition: all 0.3s ease-in-out;


// RESPONSIVE

    // DESKTOP

    // desktop-xxl x > 2559px (min)
    $screen-desktop-xxl : 2559px;

    // desktop-xl x < 1920px (max) - BASIC CSS
    $screen-desktop-xl : 1920px;
    
    // desktop-lg x < 1720px (max)
    $screen-desktop-break-custom: 1720px;

    // desktop-lg x < 1600px (max)
    $screen-desktop-lg: 1600px;

    // desktop-md x < 1440px (max)
    $screen-desktop-md: 1440px;

    // desktop-sm x < 1366px (max)
    $screen-desktop-sm: 1366px;

    // desktop-xs x < 1200px (max)
    $screen-desktop-xs-big: 1280px;

    // desktop-xs x < 1200px (max)
    $screen-desktop-xs: 1200px;


    $break-desktop: 1400px;


    // TABLET

    // tablet landscape x < 1024px (max)
    $screen-tablet-l: 1024px;

    // tablet portrait x < 768px (max)
    $screen-tablet-p: 768px;


    // custom-lower-tablet-p x < 600 (max)
    $break-tablet: 740px;



    // MOBILE

    // mobile landscape x < 480 (max)
    $screen-mobile-l: 480px;

    // mobile portrait x < 380 (max)
    $screen-mobile-p: 380px;
    

    $menu-breakpoint: 1000px; // CUSTOM WIDTH


// TIPOGRAPHY

    // WEIGHTS

        $w-thin     : 100;      // thin     100
        $w-xlight   : 200;      // xlight   200
        $w-light    : 300;      // light    300
        $w-regular  : 400;      // regular  400
        $w-medium   : 500;      // medium   500
        $w-sbold    : 600;      // sbold    600
        $w-bold     : 700;      // bold     700
        $w-sblack   : 800;      // sblack   800
        $w-black    : 900;      // black    900


    // STYLES

        $s-normal   : normal;
        $s-italic   : italic;
        $s-oblique  : oblique;


    // FONTS


        // ROBOTO

            // HOW TO USE
            // @include rp-font-family(var(--font-a), $w-regular, $s-normal);
            // @include rp-family-a();
        
            // ALL
            
            $font-a__medium : 'BridgeHeadExt-Medium', serif;
            $font-a__bold   : 'BridgeHeadExt-Bold', serif;
            $font-a__black  : 'BridgeHeadCon-Black', serif;

            $font-b__regular        : 'PTSerif-Regular', serif;
            $font-b__italic         : 'PTSerif-Italic', serif;
            $font-b__bold           : 'PTSerif-Bold', serif;
            $font-b__bold-italic    : 'PTSerif-BoldItalic', serif;


    // SIZES

        // RESPONSIVE FONT SIZE

        $display-1: (
            md: (
                font-size       : 76px,
                line-height     : 108px,
                letter-spacing  : -1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            sm: (
                font-size       : 50px,
                line-height     : 60px,
                letter-spacing  : -1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            xs: (
                font-size       : 45px,
                line-height     : 60px,
                letter-spacing  : -1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
        );

        $display-2: (
            md: (
                font-size       : 48px,
                line-height     : 48px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            sm: (
                font-size       : 48px,
                line-height     : 48px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            xs: (
                font-size       : 48px,
                line-height     : 48px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
        );

        $display-3: (
            md: (
                font-size       : 28px,
                line-height     : 31px,
                letter-spacing  : -0.2px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            sm: (
                font-size       : 28px,
                line-height     : 31px,
                letter-spacing  : -0.2px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            xs: (
                font-size       : 28px,
                line-height     : 31px,
                letter-spacing  : -0.2px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
        );

        $display-4: (
            md: (
                font-size       : 20px,
                line-height     : 22px,
                letter-spacing  : -0.21px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            sm: (
                font-size       : 20px,
                line-height     : 22px,
                letter-spacing  : -0.21px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            xs: (
                font-size       : 20px,
                line-height     : 22px,
                letter-spacing  : -0.21px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
        );

        $body: (
            md: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );

        $caption: (
            md: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );

        // TODO ?
        $button:(
            md: (
                font-size       : 21px,
                line-height     : 28px,
                letter-spacing  : -0.3px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 21px,
                line-height     : 28px,
                letter-spacing  : -0.3px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 21px,
                line-height     : 28px,
                letter-spacing  : -0.3px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );



// CUSTOM VARS

    // MAX WIDTH LOGO
        $max-width-logo : 260px;


// CUSTOM SCROLLBAR VARS

    // SIZE
        $scrollbar__size : 8px;
        $scrollbar-button__size : 1px;

    // RAIL
        $scrollbar-track__bg : transparent;
        $scrollbar-track__border : 1px solid transparent;
        $scrollbar-track__border-radius : 10px;

    // BUTTON
        $scrollbar-thumb__bg : #707070;
        $scrollbar-thumb__border : 1px solid #FEFEFE;
        $scrollbar-thumb__border-radius : 10px;




// GENERIC

    // SPACE
        $spaces: (
            160,
            128,
            96,
            80,
            64,
            48,
            32,
            24,
            16,
            8,
            4,
            0
        );


    // VARS

    $total-columns__md: 12;
    $total-columns__sm: 8;
    $total-columns__xs: 4;

    $grid-gutter-width__md: 40px;
    $grid-gutter-width__sm: 24px;
    $grid-gutter-width__xs: 24px;
    
    $container__width : 1376px;
    $container-padding__md : 32px;
    $container-padding__sm : 32px;
    $container-padding__xs : 32px;

    $padding-bottom-p: 20px;

    $header-height__md: 120px;
    $header-height__sm : 80px;
    $header-height__xs : 80px;
