@import "../../bemit/bemit-settings-and-tools.scss";

.c-list-timelines{
    
    $b : &;
    $decades: 14;
    $item__w : 2.5;

    --item-h: 5vw;
    
    @include rp-prepare-debug();

    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    // height: calc(100% - 82px);
    height: auto;
    position: relative;
    padding-bottom: 60px;
    // overflow: hidden;

    @include md{

        padding-bottom: 20px;

    }

    &__wrapper-top{

        position: relative;
        height: auto;
        width: 100%;
        flex: 1 0 auto;
        min-height: 15vw;
        display: none;

        @include md{

            display: block;

        }

    }

    &__wrapper-timelines{

        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 0px;
        display: none;

        @include md{
            
            display: block;

        }

    }

    &__timelines{

        width: 100%;
        display: flex;

    }

    &__decade{

        width: calc(100% / #{$decades});
        display: block;
        text-align: center;

        &-line{

            width: 1px;
            display: block;
            margin: 0 auto;
            height: 40px;
            background-color: gray;

        }

        &-text{

            $current-font: (
                md: (
                    letter-spacing  : -0.2px,
                    font-family     : var(--font-a__bold),
                    font-weight     : normal
                ),
                sm: (
                    letter-spacing  : -0.2px,
                    font-family     : var(--font-a__bold),
                    font-weight     : normal
                ),
                xs: (
                    letter-spacing  : -0.2px,
                    font-family     : var(--font-a__bold),
                    font-weight     : normal
                ),
            );

            @include rp-font($current-font);

            --this-fz: 1vw;

            @include media-max(1700px){

                --this-fz: 0.9vw;

            }

            font-size: var(--this-fz);
            line-height: 1.3em;

            width: 100%;
            height: auto;
            padding: 16px 0px;
            display: block;
            text-align: center;
            color: gray;

        }

    }

    &__wrapper-bot{
        
        position: relative;
        height: auto;
        width: 100%;
        flex: 1 0 auto;
        min-height: 15vw;
        display: none;

        @include md{
            
            display: block;

        }

    }

    &__item{

        position: absolute;
        top: calc(var(--top)*var(--item-h));
        left: var(--left);
        width: calc( ( 100% / #{$decades} ) * #{$item__w} );
        display: block;
        padding: 0px 0px;

        &--top{

            top: auto;
            bottom: calc(var(--bot)*var(--item-h));

        }
        
    }

    &__item:hover &__name{

        @include md{

            color: var(--current-primary-color-alt);
    
        }

    }

    &__item:hover &__years{

        @include md{

            background-color: var(--current-primary-color-alt);
    
        }

    }

    &__item:hover &__title{

        @include md{

            color: var(--current-primary-color-alt);
    
        }

    }

    &__wrapper-years{

        display: block;
        width: 100%;
        padding-bottom: 5px;

    }

    &__years{

        display: block;
        width: calc( ( ( 100% / #{$item__w} ) / 10 ) * var(--years) );
        background-color: var(--current-primary-color);
        height: 0.7vw;
        border-radius: 0.25em;
        transition: all 0.2s ease-in-out;

        @include media-max(1700px){

            height: 0.6vw;

        }

    }

    &__wrapper-name{

        display: block;
        width: 100%;
        max-width: 100%;
        padding-bottom: 0px;
        white-space: nowrap;

    }

    &__name{

        $current_font: (
            md: (
                letter-spacing  : -0.5px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            sm: (
                letter-spacing  : -0.5px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            xs: (
                letter-spacing  : -0.5px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        --this-fz: 1.6vw;

        @include media-max(1700px){

            --this-fz: 1.3vw;

        }

        font-size: var(--this-fz);
        line-height: 1.2em;

        display: block;
        width: 100%;
        color: var(--current-primary-color);
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;        
        
        &-start{

            width: 100%;
            padding: 0px 0px;
            display: block;

            @include sm(){

                display: inline;
                width: auto;
                padding: 0px;
    
            }
            
        }

        &-sep{

            display: inline;
            padding: 0px 10px;

            &::before{

                content: "-";
                display: inline-block;

            }
            
        }

        &-end{

            width: 100%;
            padding: 0px 0px;
            display: block;

            @include sm(){

                display: inline;
                width: auto;
                padding: 0px;
    
            }
            
        }

    }

    &__wrapper-title{

        display: block;
        width: 260px;
        max-width: 100%;

        @include media-max($break-tablet){

            width: 100%;
            text-align: center;
            padding-top: 20px;

        }

    }

    &__title{

        $current_font: (
            md: (
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            sm: (
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            xs: (
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        --this-fz: 0.7vw;

        font-size: var(--this-fz);
        line-height: 1.3em;

        display: block;
        width: 100%;
        color: var(--current-primary-color);
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;

    }

    // RESP

    &__wrapper-resp{

        display: flex;
        flex-direction: column;
        width: 400px;
        max-width: 100%;
        margin: 0 auto;
        position: relative;
        padding: 5px 0px;

        @include md{
            
            display: none;

        }

    }

    &__r-line{

        position: absolute;
        top: 0px;
        left: 148px;
        width: 1px;
        height: 100%;
        background-color: gray;
        display: block;
        z-index: 1;

    }

    &__r-item{

        display: inline-flex;
        align-items: flex-start;
        align-self: flex-start;
        width: auto;
        margin-bottom: 14px;
        position: relative;
        z-index: 2;
    
    }

    &__r-wrapper-name{

        display: block;
        width: auto;
        max-width: 100%;
        padding-bottom: 0px;
        white-space: nowrap;
        padding-right: 40px;
        position: relative;

        &::before{

            content: "";
            display: block;
            position: absolute;
            top: 12px;
            right: 16px;
            width: 7px;
            height: 7px;
            background-color: var(--current-primary-color);
            border-radius: 50%;

        }

    }

    &__r-name{

        $current_font: (
            md: (
                letter-spacing  : -0.5px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            sm: (
                letter-spacing  : -0.5px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            xs: (
                letter-spacing  : -0.5px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        font-size: 22px;
        line-height: 32px;
        display: flex;
        justify-content: flex-end;
        width: 128px;
        color: var(--current-primary-color);
        text-transform: uppercase;
        
        &-start{

            width: auto;
            padding: 0px 0px;
            display: block;
            
        }

        &-sep{

            display: inline;
            padding: 0px 5px;

            &::before{

                content: "-";
                display: inline-block;

            }
            
        }

        &-end{

            width: auto;
            padding: 0px 0px;
            display: block;
            
        }

    }

    &__r-wrapper-title{

        display: block;
        width: auto;
        padding-top: 7px;

    }

    &__r-title{

        $current_font: (
            md: (
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            sm: (
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            xs: (
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);


        font-size: 16px;
        line-height: 18px;
        display: block;
        width: auto;
        color: var(--current-primary-color);
        text-transform: uppercase;

    }

}

@include md{

    .is-timeline{

        .o-page-transition__inner{

            height: 100%;
    
        }

    }

}
