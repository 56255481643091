.o-grid{

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: auto;
    grid-auto-flow: row dense;
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    &__item{

        display: block;
        overflow: hidden;
        position: relative;
    
        &--1-1{

            @include o-grid-item(1, 1);
            
        }
    
        &--1-2{
    
            @include o-grid-item(1, 2);
    
        }
    
        &--2-1{
    
            @include o-grid-item(2, 1);
    
        }

        &--2-2{
    
            @include o-grid-item(2, 2);
    
        }

        &--4-2{
    
            @include o-grid-item(4, 2);
    
        }
    
    }

}
