.o-button{

    @include rp-font($body);

    position: relative;
    width: auto;
    padding-left: 40px;
    text-transform: uppercase;
    color: $color-a;
    cursor: pointer;

    &:hover{
        
    }

}
