@import "../../bemit/bemit-settings-and-tools.scss";


.b-loader{

    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    z-index: 999999999;
    background-color: $black;

    &__item{

        border-radius: 50%;
        padding: 8px;
        border: 2px solid transparent;
        animation: rp_rotate linear 0.7s infinite;
        border-top-color: $white;
        position: relative;
        margin: 75px auto;
        width: 80px;
        height: 80px;
        display: block;
        overflow: hidden;
        will-change: transform;
        
    }

}
