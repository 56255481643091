@import "../../bemit/bemit-settings-and-tools.scss";

.c-label-page{
    
    $b : &;

    $icon_w: 30px;
    
    @include rp-prepare-debug();

    padding-bottom: 23px;
    display: block;

    &__wrapper{

        display: flex;
        width: 100%;
        align-items: flex-start;
        padding-bottom: 31px;

    }

    &--highlight &__wrapper{

        padding-bottom: 11px;

    }

    &--show-border-bottom &__wrapper{

        border-bottom: 1px solid var(--current-primary-color);

    }

    &__wrapper-label{

        display: block;
        width: 100%;

    }

    &--show-icon &__wrapper-label{

        width: calc(100% - #{$icon_w});

    }

    &__label{

        $current_font: (
            md: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__italic),
                font-weight     : normal
            ),
            sm: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__italic),
                font-weight     : normal
            ),
            xs: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__italic),
                font-weight     : normal
            )
        );

        @include rp-font($current_font);

        display: block;
        width: 100%;
        color: $black;
        font-style: italic;

    }

    &--highlight &__label{

        $current_font: (
            md: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            sm: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            xs: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            )
        );

        @include rp-font($current_font);

        display: block;
        width: 100%;
        font-style: normal;
        text-transform: uppercase;
        color: var(--current-primary-color);

        
    }

    &__wrapper-close{

        display: flex;
        width: $icon_w;

    }

}
