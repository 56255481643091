@import "../../bemit/bemit-settings-and-tools.scss";

.c-movie{
    
    $b : &;
    $col_right_width: 160px;
    $col_left_padd_right : 26px;
    
    @include rp-prepare-debug();

    padding: 20px 0px;
    display: block;

    &__container{
        
    }

    &__col{

    }

    &__wrapper-title{

        display: block;
        width: 100%;
        padding-bottom: 30px;

    }

    &__title{

        $current_font: (
            md: (
                font-size       : 48px,
                line-height     : 58px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            sm: (
                font-size       : 48px,
                line-height     : 58px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            xs: (
                font-size       : 48px,
                line-height     : 58px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        display: block;
        width: calc(100% - #{$col_right_width});
        color: $black;
        padding-right: $col_left_padd_right;

        @include media-max($break-tablet){

            width: 100%;
            padding-right: 0px;
    
        }

    }

    &__body{

        display: flex;
        width: 100%;
        padding-bottom: 40px;

        @include media-max($break-tablet){

            display: block;
    
        }

    }

    &__wrapper-image{

        display: block;
        width: calc(100% - #{$col_right_width});
        padding-right: $col_left_padd_right;

        @include media-max($break-tablet){

            width: 100%;
            padding-right: 0px;
    
        }

    }

    &__image{

        display: block;
        width: 100%;
        height: auto;

    }

    &__wrapper-detail{

        display: flex;
        flex-direction: column;
        width: $col_right_width;

        @include media-max($break-tablet){

            width: 100%;
            display: block;
            padding-top: 20px;
    
        }

    }

    &__details{

        align-items: flex-start;
        flex: 1 0 auto;

        $current-font: (
            md: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            sm: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            xs: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        b, strong{

            font-family     : var(--font-b__bold)!important;

            i{

                font-family     : var(--font-b__bold-italic)!important;

            }

        }

        i{

            font-family     : var(--font-b__italic)!important;

            b, strong{

                font-family     : var(--font-b__bold-italic)!important;

            }

        }

    }

    &__detail-caption{
        
        text-transform: uppercase;

    }

    &__detail-see{

        width: $col_right_width;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: flex-start;

        @include media-max($break-tablet){

            width: 100%;
            margin-top: 20px;
    
        }

    }

    &__iframe{

        width: 100%;
        border: 0;
        height: 34px;
        margin-top: 4px;

    }

    &__wrapper-info{

        width: calc(100% - #{$col_right_width});
        padding-right: $col_left_padd_right;
        display: block;
        padding-bottom: 0px;

        @include media-max($break-tablet){

            width: 100%;
            padding-right: 0px;
    
        }

    }

    &__info-text{

        $current-font: (
            md: (
                font-size       : 28px,
                line-height     : 31px,
                letter-spacing  : -0.2px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            sm: (
                font-size       : 28px,
                line-height     : 31px,
                letter-spacing  : -0.2px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            xs: (
                font-size       : 28px,
                line-height     : 31px,
                letter-spacing  : -0.2px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        color: var(--current-primary-color);
        text-transform: uppercase;
        display: inline;

    }

    &__info-sep{

        $current-font: (
            md: (
                font-size       : 28px,
                line-height     : 31px,
                letter-spacing  : -0.2px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 28px,
                line-height     : 31px,
                letter-spacing  : -0.2px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 28px,
                line-height     : 31px,
                letter-spacing  : -0.2px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        color: var(--current-primary-color);
        text-transform: uppercase;
        padding-left: 6px;
        display: inline;

    }

    &__wrapper-bottom{

        width: 100%;
        display: flex;

        @include media-max($break-tablet){

            display: block;
    
        }

    }

    &__wrapper-content{

        width: 100%;
        display: block;
        padding-bottom: 18px;

        @include md{

            padding-right: 10%;

        }

    }

    &__wrapper-content + &__wrapper-description{

        padding-top: 8px;

    }
    
    &__content-title{

        $current_font: (
            md: (
                font-size       : 20px,
                line-height     : 26px,
                letter-spacing  : 0.15px,
                font-family     : var(--font-b__bold-italic),
                font-weight     : normal
            ),
            sm: (
                font-size       : 20px,
                line-height     : 26px,
                letter-spacing  : 0.15px,
                font-family     : var(--font-b__bold-italic),
                font-weight     : normal
            ),
            xs: (
                font-size       : 20px,
                line-height     : 26px,
                letter-spacing  : 0.15px,
                font-family     : var(--font-b__bold-italic),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        display: inline;
        color: var(--current-primary-color-alt);
        padding-right: 3px;

    }

    &__the-content{

        $current_font: (
            md: (
                font-size       : 20px,
                line-height     : 26px,
                letter-spacing  : 0.15px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            sm: (
                font-size       : 20px,
                line-height     : 26px,
                letter-spacing  : 0.15px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            xs: (
                font-size       : 20px,
                line-height     : 26px,
                letter-spacing  : 0.15px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        display: inline;

    }

    &__button{

        width: 100%;
        border: 0;
        height: 34px;

    }

    &__wrapper-extra{

        width: 100%;
        display: flex;
        padding-top: 20px;

        @include media-max($break-tablet){

            display: block;
    
        }

    }

    &__col-related{

        width: calc(100% - #{$col_right_width});
        display: block;
        padding-right: $col_left_padd_right;

        @include media-max($break-tablet){

            width: 100%;
            padding-right: 0px;
            padding-bottom: 40px;
    
        }

    }

    &__col-poster{

        width: $col_right_width;
        display: block;

        @include media-max($break-tablet){

            width: 100%;
    
        }

    }

    &__wrapper-poster{

        width: 100%;
        display: block;
        position: sticky;
        top: 0px;

    }

    &__poster{

        width: 100%;
        display: block;
        cursor: pointer;
        filter: grayscale(1);
        transition: all 0.2s ease-in-out;

        &:hover{

            filter: grayscale(0);

        }

    }
    
    &__wrapper-block-title{

        display: block;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

    }

    &__wrapper-description{

        display: block;
        width: 100%;
        padding-bottom: 18px;

        @include md{
            
            padding-right: 10%;

        }

    }

    &__the-description{

        $current-font: (
            md: (
                font-size       : 14px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            sm: (
                font-size       : 14px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            xs: (
                font-size       : 14px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        display: block;
        width: 100%;

    }

    &__block-title{

        $current-font: (
            md: (
                font-size       : 16px,
                line-height     : 22px,
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            sm: (
                font-size       : 16px,
                line-height     : 22px,
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            xs: (
                font-size       : 16px,
                line-height     : 22px,
                letter-spacing  : 1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        display: block;
        width: 100%;
        padding-top: 10px;
        border-top: 1px solid var(--current-primary-color);
        text-transform: uppercase;
        color: var(--current-primary-color);
        
    }

    &__wrapper-block{
    
        padding: 10px 0px;
        display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
        grid-column-gap: 0px;
        grid-row-gap: 40px;
    
        @include media-max($break-tablet){
    
            // padding: 0px;
            // display: flex;
            // flex-wrap: nowrap;
            // overflow-y: hidden;
            // overflow-x: auto;
            // padding-bottom: 20px;

            // grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
            grid-template-columns: repeat(2, 1fr);
    
        }
    
    }

    &__wrapper-rows{
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    &__wrapper-block-timeline{
    
        padding: 10px 0px;
        display: block;
    
        @include media-max($break-tablet){
    
            padding: 0px;
            padding-bottom: 20px;
    
        }
    
    }
    
    &__item{

        $row_gap : 105px;
    
        position: relative;
        width: auto;
        display: block;
        grid-column-end: span 1;
        grid-row-end: span 1;
        // padding: 0px 20px;
    
        @include media-max($break-tablet){
    
            padding: 0px 10px;
            
        }
    
        &::before{
    
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 1px;
            height: 100%;
            background-color: var(--current-primary-color);
            transition: background-color 0.1s ease-in-out 0.2s;
    
        }

        &:nth-child(5n + 1){

            @include media-min($break-tablet + 1){

                &::before{

                    display: none;

                    width: calc( (100%*5));
                    left: 0px;
                    height: 1px;
                    top: calc(0px - (40px/2)); // BORDER ON TOP
                    // top: calc(100% + (40px/2)); // BORDER ON BOTTOM
    
                }

            }
            
        }

        &:first-child::before{

            display: none;

        }
        
        @include media-max($break-tablet){

            &:nth-child(2n + 1){

                &::before{
    
                    width: calc( (100%*2));
                    left: 0px;
                    height: 1px;
                    top: calc(0px - (40px/2)); // BORDER ON TOP
                    // top: calc(100% + (#{$row_gap}/2)); // BORDER ON BOTTOM
    
                }
                
            }

        }

        &--book{
            display: flex;
            padding: 0px 10px;
            &::before{
                display: none;
            }
        }

    }
    
    &__wrapper-item-image{
    
        display: block;
        width: 100%;
        max-width: 100%;
        padding-bottom: 10px;

        &--book{
            width: auto;
            padding-right: 20px;
            padding-bottom: 0;
        }
    
    }
    
    &__item-image{
    
        display: block;
        width: 100px;
        height: 72px;
        margin: 0 auto;
        max-width: 100%;
        object-fit: contain;

        &--book{
            width: 140px;
            height: auto;
        }
    
        @include media-max($break-tablet){
    
            // height: 30vh;
            // object-fit: contain;
            // max-height: 300px;
            width: auto;
            height: 90px;
    
        }
    
    }
    
    &__wrapper-item-title{
    
        display: block;
        width: 180px;
        max-width: 100%;
        margin: 0 auto;

        @include media-max($break-tablet){

            width: 100%;
            margin: 0px;
            // padding: 0px 14px;

        }
        &--book{
            width: auto;
        }
    }
    
    &__item-title{
    
        $current_font: (
            md: (
                font-size       : 12px,
                line-height     : 14px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 12px,
                line-height     : 14px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 12px,
                line-height     : 14px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );
    
        @include rp-font($current_font);
    
        display: block;
        width: 100%;
        color: var(--color-black);
        text-transform: uppercase;
        text-align: center;
    
        &--book{

            $current-font: (
                md: (
                    font-size       : 16px,
                    line-height     : 22px,
                    letter-spacing  : 1px,
                    font-family     : var(--font-a__black),
                    font-weight     : normal
                ),
                sm: (
                    font-size       : 16px,
                    line-height     : 22px,
                    letter-spacing  : 1px,
                    font-family     : var(--font-a__black),
                    font-weight     : normal
                ),
                xs: (
                    font-size       : 16px,
                    line-height     : 22px,
                    letter-spacing  : 1px,
                    font-family     : var(--font-a__black),
                    font-weight     : normal
                ),
            );

            @include rp-font($current-font);

            display: block;
            width: auto;
            padding-top: 10px;
            text-transform: uppercase;
            color: var(--current-primary-color);
            padding-bottom: 6px;
            text-align: left;

        }

    }
    
    &__timeline{

        $current_font: (
            md: (
                font-size       : 48px,
                line-height     : 58px,
                letter-spacing  : -0.5px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            sm: (
                font-size       : 48px,
                line-height     : 58px,
                letter-spacing  : -0.5px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            xs: (
                font-size       : 36px,
                line-height     : 42px,
                letter-spacing  : -0.5px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        display: inline-flex;
        align-items: center;
        width: auto;
        color: var(--color-black);
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        
        &:hover{

            @include md{

                color: var(--current-primary-color);

            }

        }

        @include media-max($break-tablet){
    
            // display: block;
    
        }
        
        &-start{

            @include media-max($break-tablet){
    
                display: block;
                width: 100%;
                text-align: center;
        
            }

        }

        &-sep{

            display: inline-block;
            height: 2px;
            background-color: var(--color-black);
            width: 50px;
            margin: 0px 6px;
            margin-right: 3px;
            transition: all 0.3s ease-in-out;


            @include media-max($break-tablet){
    
                display: block;
                margin: 30px auto;
        
            }
            
        }

        &:hover &-sep{

            @include md{

                background-color: var(--current-primary-color);

            }

        }

        &-end{
            
            @include media-max($break-tablet){
    
                display: block;
                width: 100%;
                text-align: center;
        
            }

        }

    }

    &__wrapper-item-authors{
    
        display: block;
        width: 180px;
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: 6px;

        @include media-max($break-tablet){

            width: 100%;
            margin: 0px;
            // padding: 0px 14px;

        }
        &--book{
            width: auto;
        }
    
    }
    
    &__item-authors{
    
        $current-font: (
            md: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            sm: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
            xs: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__bold),
                font-weight     : normal
            ),
        );
    
        @include rp-font($current_font);
    
        display: block;
        width: 100%;
        color: var(--color-black);
        text-align: center;

        &--book{
            width: auto;
            text-align: left;
        }
    
    }

    &__wrapper-item-editorial{
    
        display: block;
        width: 180px;
        max-width: 100%;
        margin: 0 auto;

        @include media-max($break-tablet){

            width: 100%;
            margin: 0px;
            // padding: 0px 14px;

        }

        &--book{
            width: auto;
        }
    
    }
    
    &__item-editorial{
    
        $current-font: (
            md: (
                font-size       : 14px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            sm: (
                font-size       : 14px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            xs: (
                font-size       : 14px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);
    
        display: block;
        width: 100%;
        color: var(--color-black);
        text-align: center;

        &--book{
            text-align: left;
        }
    
    }

}
