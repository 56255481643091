@import "../../bemit/bemit-settings-and-tools.scss";

.c-text{
    
    $b : &;
    
    @include rp-prepare-debug();

    padding: 80px 0px;
    display: block;

    &.is-vertical{

        padding-top: 0px;

    }

    &__container{
        
    }

    &__col{

    }

    &__inner{

    }

    &__wrapper-text{

        width: auto;

    }

    &__text{

        color: $black;
        display: block;
        width: 100%;
        
        .is-horizontal &{

            @include rp-font($display-3);

            font-family: var(--font-a__medium)!important;
            text-align: right;

        }

        .is-vertical &{

            @include rp-font($body);

            text-align: center;

        }

    }

    &__wrapper-image{

        display: block;
        width: 100%;
        position: relative;

        .is-vertical &{

            display: flex;
            justify-content: center;
            transform: translateY(-50%);

        }

    }

    &__image{

        display: block;
        width: 160px;
        height: auto;
        max-width: 100%;
        position: relative;
        z-index: 2;

    }

}

