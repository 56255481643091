@import "../../bemit/bemit-settings-and-tools.scss";


.b-menu-dropdown{

    $b : &;
    
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    display: block;
    position: fixed;
    z-index: 9999999;
    background-color: var(--color-white);
    transition: all 0.2s ease-in-out 0.1s, transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateY(101%);

    &.is-show{

        transform: translateY(0px);

    }

    @include md{

        display: none;
    
    }

    &__wrapper-hamburguer{

        position: absolute;
        top: -26px;
        left: 50%;
        width: auto;
        transform: translate(-50%, -100%);
        display: block;
        width: 46px;
        height: 46px;
        cursor: pointer;
        background-color: #02FFA6;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

    }

    &__hamburguer{

        $icon__width : 21px;
        $icon__height : 18px;
        $icon__bar_width : 2px;
        $icon__bar_border_radius : 0px;
        $icon__bar_color : var(--color-black);
        $icon__distance_bars : 8px;

        width: $icon__width;
        height: $icon__height;
        display: flex;
        align-items: center;
        justify-content: center;

        &-icon{

            transition: all 0.5s ease-in-out;
            width: $icon__width;
            background-color: $icon__bar_color;
            height: $icon__bar_width;
            border-radius: $icon__bar_border_radius;
            position: relative;
            display: block;

            .is-show &{

                background-color: transparent;
                
            }

            &::before,
            &::after{

                content: "";
                position: absolute;
                left: 0px;
                width: $icon__width;
                height: $icon__bar_width;
                border-radius: $icon__bar_border_radius;
                background-color: $icon__bar_color;
                transition: all 0.5s ease-in-out;
                transform: rotate(0deg);
                transform-origin: center;

                .is-show &{

                    background-color: $icon__bar_color;
        
                }

            }

            &::before{

                top: -($icon__distance_bars);
                opacity: 1;
                visibility: visible;

                .is-show &{

                    top: 0px;
                    transform: rotate(45deg);

                }

            }

            &::after{

                top: $icon__distance_bars;
                opacity: 1;
                visibility: visible;

                .is-show &{

                    top: 0px;
                    transform: rotate(-45deg);

                }

            }

        }

    }

    &__menu{

        display: block;
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;     

    }

    &__menu-item{

        width: 100%;
        height: auto;
        position: relative;
        cursor: pointer;
        transition: all 0.6s ease-in-out;
        display: block;
        padding: 14px 0px;

        &::before{
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: #dfdcd9;
            z-index: 1;
            transition: all 0.6s ease-in-out;
        }

        &::after{
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgba($black, 0.49);
            background: linear-gradient(to bottom, rgba($black, 0.2) 0%, rgba($black, 0) 20%);
            opacity: 0.5;
            z-index: 2;
            transition: all 0.6s ease-in-out;

        }

        &:first-child{

            display: none;
            
        }

        &.is-active{

            width: 100%;

            &::before{

                // background-color: transparent;

            }

            &::after{

                // opacity: 1;
                // background-color: transparent;
                background: linear-gradient(to bottom, rgba(40, 30, 30, 0) 0%, rgba(40, 30, 30, 0) 20%);
                box-shadow: 0px 0px 14px 0px rgba(40, 30, 30, 0.6);
                
            }

        }
        
    }

    &__menu-item-text{

        $current-font: (
            md: (
                font-size       : 80px,
                line-height     : 108px,
                letter-spacing  : -1px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            sm: (
                font-size       : 40px,
                line-height     : 50px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
            xs: (
                font-size       : 40px,
                line-height     : 50px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__black),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        color: var(--color-black);
        position: relative;
        z-index: 999;
        padding-left: 16px;
        transition: all 0.6s ease-in-out;

        .is-active &{

            color: var(--current-primary-color);

        }
        
    }

    &__menu-buttons{

        width: 100%;
        display: block;
        color: var(--color-black);
        position: static;
        transition: all 0.6s ease-in-out;

    }

    &__button{

        width: 100%;
        display: flex;
        align-items: center;
        height: auto;
        background-color: #02FFA6;
        padding-left: 6px;

    }

    &__iframe{

        width: 100%;
        display: block;
        border: 0px;
        height: 38px;

    }

    &__wrapper-dropdown-footer{
        width: 100%;
        background-color: var(--color-black);
        padding: 20px 0px;

    }

    &__language-picker {

        color: white;
        height: 35px;
        text-align: center;

    }

    &__language-link {
        color: white;
        padding: 0 10px;

        &.is-active {

            font-weight: bold;

        }
    }
    
    &__logo-partner-link{

        display: flex;
        justify-content: center;
        align-items: flex-start;

    }

}
