.o-overflow{

    &-hidden{

        overflow: hidden;

        &\@xs{
            @include only-xs{
                overflow: hidden;
            }
        }
        &\@sm{
            @include only-sm{
                overflow: hidden;
            }
        }
        &\@md{
            @include only-md{
                overflow: hidden;
            }
        }

    }

}