.s-content{

    word-break: break-word;

    h1{

        @include rp-font($display-1);
        
    }


    h2{

        @include rp-font($display-2);
        
    }


    h3{

        @include rp-font($display-3);
        
    }


    h4{

        @include rp-font($body);

    }

    h5, 
    h6{

        @include rp-font($body);
        
    }


    ul{

        display: block;
        width: 100%;

    }

    li{

        display: block;
        width: 100%;
        text-indent: 20px;
        // margin-bottom: 20px;
        position: relative;

        &::before{

            content: "";
            position: absolute;
            top: 1em;
            left: 0px;
            width: 6px;
            height: 6px;
            background-color: $black;
            transform: translateY(-50%);
            border-radius: 50%;

        }

    }

    i, em{

        font-style: italic;

    }

    b,
    strong{

        font-weight: normal;
        font-family: var(--font-a__medium);

    }

    a{

        color: $black;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover{

            color: $black;

        }

    }

}

.s-debug{

    // Outline all classes.
    [class] {
        outline: 3px solid lightgrey;
    }

    // Outline all BEM Elements.
    [class*="__"] {
        outline-color: grey;
    }

    // Outline all BEM Modifiers.
    [class*="--"] {
        outline-color: rgb(72, 72, 72);
    }

    // Outline all Object classes.
    [class^="o-"],
    [class*=" o-"] {
        outline-color: orange;
    }

    // Outline all Component classes.
    [class^="c-"],
    [class*=" c-"] {
        outline-color: lightseagreen;
    }


    // Outline all utility classes.
    [class^="u-"],
    [class*=" u-"] {
        outline-color: lightcoral;
    }


    // Outline all state classes.
    [class^="is-"],
    [class*=" is-"], 
    [class^="has-"],
    [class*=" has-"]{
        outline-color: yellow;
    }

    // Outline all js classes.
    [class^="js-"],
    [class*=" js-"] {
        outline-color: darkmagenta;
    }

    // Outline all theme classes.
    [class^="t-"],
    [class*=" t-"] {
        outline-color: darkblue;
    }

    // Outline all scope classes.
    [class^="s-"],
    [class*=" s-"] {
        outline-color: darkorange;
    }

    // Outline all qa classes.
    [class^="qa-"],
    [class*=" qa-"] {
        outline-color: lightpink;
    }

    // Outline all Hack classes.
    [class^="_"] {
        outline-color: red;
    }

    // Outline all Responsive classes.
    [class*="@"] {
        outline-color: greenyellow;
    }

}