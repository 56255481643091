@import "../../bemit/bemit-settings-and-tools.scss";

.c-map{
    
    $b : &;
    
    @include rp-prepare-debug();

    padding: 20px 0px;
    display: block;
    padding-top: 0px;
    padding-bottom: 0px;

    @include md{

        padding-bottom: 20px;

    }

}
