// =========================================================================================
// TOOLS - MIXINS
// =========================================================================================

@mixin sm() {
    @media (min-width: ($break-tablet + 1)) {
        @content;
    }
}

@mixin md() {
    @media (min-width: ($break-desktop + 1)) {
        @content;
    }
}

// RANGE
@mixin only-xs() {
    @media (max-width: $break-tablet) {
        @content;
    }
}
@mixin only-sm() {
    @media (min-width: ($break-tablet + 1)) and (max-width: $break-desktop) {
        @content;
    }
}
@mixin only-md() {
    @media (min-width: ($break-desktop + 1)) {
        @content;
    }
}
@mixin media-min($mq) {
    @media (min-width: $mq) {
        @content;
    }
}
@mixin media-max($mq) {
    @media (max-width: $mq) {
        @content;
    }
}
@mixin media-between($mq_min, $mq_max) {
    @media (min-width: $mq_min) and (max-width: $mq_max) {
        @content;
    }
}


// =========================================================================================
// RP - CUSTOM SCROLLBAR
// =========================================================================================
// Browser Support: Chrome, Safari, Opera +15, Android, iOS
@mixin rp-custom-scrollbar( 
    $scrollbar__size : 8px,
    $scrollbar-button__size: 1px,
    $scrollbar-track__bg : transparent,
    $scrollbar-track__border : 1px solid transparent,
    $scrollbar-track__border-radius : 10px,
    $scrollbar-thumb__bg : #303030,
    $scrollbar-thumb__border : 1px solid #FEFEFE,
    $scrollbar-thumb__border-radius : 10px
){

    &::-webkit-scrollbar {

        width: $scrollbar__size;
        height: $scrollbar__size;

    }

    &::-webkit-scrollbar-button {

        width: $scrollbar-button__size;
        height: $scrollbar-button__size;

    }

    &::-webkit-scrollbar-thumb {

        background: $scrollbar-thumb__bg;
        border: $scrollbar-thumb__border;
        border-radius: $scrollbar-thumb__border-radius;

    }

    &::-webkit-scrollbar-track {

        background: $scrollbar-track__bg;
        border: $scrollbar-track__border;
        border-radius: $scrollbar-track__border-radius;

    }

    &::-webkit-scrollbar-corner {

        background: transparent;

    }

}

// =========================================================================================
// RP - ASPECT RATIO
// =========================================================================================
@mixin rp-aspect-ratio($padding-bottom : 56.25%){

    &:before{

        content: "";
        display: block;
        padding-bottom: $padding-bottom;

    }

}
    
// =========================================================================================
// RP - GRID
// =========================================================================================
// ! DEPRECATED
@mixin rp-grid($jc : flex-start){

    display: flex;
    flex-wrap: wrap;
    justify-content: $jc;

}
    
// =========================================================================================
// RP - GRID ITEM
// =========================================================================================
// ! DEPRECATED
// SAMPLE USAGE
// .rp-grid{

//     width: 100%;

//     @include rp-grid(center); // OPTIONAL ARGUMENT

//     &__item{

//         @include rp-grid__item(1, 20px); // THIS APPLY MARGIN BOTTOM ONLY, NOT IN MARGIN RIGHT
//         @include rp-grid__item(2, 40px, $screen-tablet-p, false);
//         @include rp-grid__item(3, 30px, $screen-tablet-l, true);

//     }

// }

@mixin rp-grid__item($items : 1, $gutter : 0px, $breakpoint : 0, $gutterBottom: true, $offset : 0){

    @if $items > 99 {

        $items : 99;

    } @else if $items < 1{

        $items : 1;

    }

    $current_offset : $items + $offset;
    $spaces : $items - 1;
    $totalGutter : $spaces * $gutter + 1; // FOR ROUNDED SUBPIXELS - DIFFERENT WAYS IN BROWSERS

    @if $spaces == 0 {

        width: 100%;
        margin-right: 0;

        @if $gutterBottom == true {

            margin-bottom: $gutter;

        }

    } @else{

        // TODO - CHANGE MEDIA

        @if $breakpoint != 0 and $breakpoint != false and $breakpoint != none{

            @media (min-width: $breakpoint) {

                width: calc( (100% - #{$totalGutter}) / #{$items} );

                &:nth-child(n){

                    margin-right: $gutter;

                }

                &:nth-child(#{$items}n+#{$current_offset}){

                    margin-right: 0;

                }

                @if $gutterBottom == true {

                    margin-bottom: $gutter;

                }

            }

        } @else {

            width: calc( (100% - #{$totalGutter}) / #{$items} );

            &:nth-child(n + #{$offset}){

                margin-right: $gutter;

            }

            &:nth-child(#{$items}n+#{$current_offset}){

                margin-right: 0;

            }

            @if $gutterBottom == true {

                margin-bottom: $gutter;

            }

        }

    }

}

    
// =============================================================================
// RP - Font Face
// =============================================================================
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    
    $src: null;

    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }

}

@mixin rp-unstyled-field {

    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    cursor: default;
    outline: 0;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
    text-align: inherit;
    
}

@mixin rp-text-overflow {

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
}

@mixin rp-icon( $icon-name: 'icon-arrow-down.svg', $icon-size: 1em, $icon-url : '../img/'){

    position: relative;

    &::before{

        content: "";
        display: inline-block;
        background-image: url(#{$icon-url}#{$icon-name});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        font-size: $icon-size;
        width: 1em;
        height: 1em;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

    }

}

@mixin rp-placeholder( $color : #000 ){

    &::-webkit-input-placeholder { // Chrome/Opera/Safari
        color: $color;
    }
    &::-moz-placeholder { // Firefox 19+
        color: $color;
    }
    &:-ms-input-placeholder { // IE 10+
        color: $color;
    }
    &:-moz-placeholder { // Firefox 18-
        color: $color;
    }

}

@mixin rp-font__set($values){

    @each $key, $val in $values{

        #{$key}: $val;
        
    }

}

@mixin rp-font($font){

    $values_xs : map-get($font, 'xs');
    $values_sm : map-get($font, 'sm');
    $values_md : map-get($font, 'md');

    // PHONE
    @include only-xs{
        @include rp-font__set($values_xs);
    }

    // TABLET
    @include only-sm{
        @include rp-font__set($values_sm);
    }

    // DESKTOP
    @include only-md{
        @include rp-font__set($values_md);
    }

}

// @mixin rp-family-a($w : $w-regular, $s: $s-normal){

//     font-family: $font-a;
//     font-weight: $w;
//     font-style: $s;

// }

@mixin rp-debug-this(){

    outline: 1px dotted fuchsia!important;
    * { outline: 1px dotted red!important; }
    * * { outline: 1px dotted cyan!important; }
    * * * { outline: 1px dotted orange!important; }
    * * * * { outline: 1px dotted blue!important; }
    * * * * * { outline: 1px solid gray!important; }
    * * * * * * { outline: 1px solid purple!important; }
    * * * * * * * { outline: 1px solid rosybrown!important; }
    * * * * * * * * { outline: 1px solid darkolivegreen!important; }
    * * * * * * * * * { outline: 1px dotted fuchsia!important; }
    * * * * * * * * * * { outline: 1px dotted red!important; }
    * * * * * * * * * * * { outline: 1px dotted cyan!important; }
    * * * * * * * * * * * * { outline: 1px dotted orange!important; }
    * * * * * * * * * * * * * { outline: 1px dotted blue!important; }
    * * * * * * * * * * * * * * { outline: 1px solid gray!important; }
    * * * * * * * * * * * * * * * { outline: 1px solid purple!important; }
    * * * * * * * * * * * * * * * * { outline: 1px solid rosybrown!important; }
    * * * * * * * * * * * * * * * * * { outline: 1px solid darkolivegreen!important; }

}

@mixin rp-prepare-debug($bg : transparent){

    $b : &;

    position: relative;

    &::after{

        content: "#{$b}";
        position: absolute;
        top: 10px;
        left: 10px;
        width: auto;
        height: auto;
        color: $black;
        display: none;
        z-index: 10;
        background-color: rgba($white, 0.7);
        white-space: nowrap;
        font-size: 14px;
        line-height: 18px;
    
    }
    
    .is-debug &{
    
        background-color: $bg;
        outline: 4px solid lightsalmon!important;
    
        &::after{
    
            display: block;
    
        }
    
    }

}

@mixin rp_col($cols : 1){

    width: calc( ( (100% - 0px) / var(--total-columns) ) * #{$cols} );

}

@mixin rp_col_push($cols : 1){

    margin-left: calc( (#{$cols} / var(--total-columns) ) * (100% - 0px) );

}

@mixin o-grid-item($w : 1, $h : 1){

    $aspect : percentage($h / $w);

    grid-column-end: span #{$w};
    grid-row-end: span #{$h};

    @include rp-aspect-ratio($aspect);

}

// USING ONLY SELECTOR NAME
// @mixin o-grid-item(){

//     $split-values: str-split(#{&}, "-");

//     $w: number(nth($split-values, -2));
//     $h: number(nth($split-values, -1));

//     $aspect : percentage($h / $w);

//     grid-column-end: span #{$w};
//     grid-row-end: span #{$h};

//     @include rp-aspect-ratio($aspect);

// }


// =========================================================================================
// END TOOLS - MIXINS
// =========================================================================================