.o-video-bg{

    // BG
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%; /* Set video container element width here */
    height: 100%; /* Set video container element height here */
    overflow: hidden;
    background: transparent; /* bg color, if video is not high enough */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    // horizontally center the video
    &__width {

        position: absolute;
        width: 100%; /* Change width value to cover more area*/
        height: 100%;
        left: -9999px;
        right: -9999px;
        margin: auto;

    }

    // set video aspect ratio and vertically center
    &__aspect {

        position: absolute;
        width: 100%;
        height: 0;
        top: -9999px;
        bottom: -9999px;
        margin: auto;
        padding-bottom: 56.25%; // 16:9 ratio
        overflow: hidden;

    }

    &__make-height {

        position: absolute;
        top: 0; 
        right: 0; 
        bottom: 0;
        left: 0;

    }
    
    &__hide-controls {

        box-sizing: content-box;
        position: relative;
        height: 100%;
        width: 100%;
        // Vimeo timeline and play button are ~55px high
        padding: 55px 97.7777px; // 16:9 ratio
        top: -55px; 
        left: -97.7777px; // 16:9 ratio

    }
    
    &__iframe,
    iframe{

        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 0 none;
        display: block;
        
    }

}
