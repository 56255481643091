.o-w-thin{

    font-weight: $w-thin;

}

.o-w-xlight{

    font-weight: $w-xlight;

}

.o-w-light{

    font-weight: $w-light;

}

.o-w-regular{

    font-weight: $w-regular;

}

.o-w-medium{

    font-weight: $w-medium;

}

.o-w-bold{

    font-weight: $w-bold;

}

.o-w-black{

    font-weight: $w-black;

}
