@font-face {
  font-family: "PTSerif-Regular";
  src: url("./PTSerif-Regular.eot");
  src: url("./PTSerif-Regular.eot?#iefix") format("embedded-opentype"),
    url("./PTSerif-Regular.woff2") format("woff2"),
    url("./PTSerif-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PTSerif-Italic";
  src: url("./PTSerif-Italic.eot");
  src: url("./PTSerif-Italic.eot?#iefix") format("embedded-opentype"),
    url("./PTSerif-Italic.woff2") format("woff2"),
    url("./PTSerif-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PTSerif-Bold";
  src: url("./PTSerif-Bold.eot");
  src: url("./PTSerif-Bold.eot?#iefix") format("embedded-opentype"),
    url("./PTSerif-Bold.woff2") format("woff2"),
    url("./PTSerif-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PTSerif-BoldItalic";
  src: url("./PTSerif-BoldItalic.eot");
  src: url("./PTSerif-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./PTSerif-BoldItalic.woff2") format("woff2"),
    url("./PTSerif-BoldItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
