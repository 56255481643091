
.o-bs-parallax{
	/*
	 * Use the same CSS variable as specified in our instance.
	 */
    transform: translateY(var(--ty));
	/*
	 * The will-change CSS property provides a way for authors to hint browsers about the kind of changes
	 * to be expected on an element, so that the browser can setup appropriate optimizations ahead of time
	 * before the element is actually changed.
	 */
    will-change: transform;

    @include media-max($break-tablet){

        transform: translateY(calc(var(--ty)*0.4));

    }

}

.o-bs-scale-img{
	/*
	 * Use the same CSS variable as specified in our instance.
	 */
    transform: scale(var(--s));
    transition: all 0.2s ease-out;
	/*
	 * The will-change CSS property provides a way for authors to hint browsers about the kind of changes
	 * to be expected on an element, so that the browser can setup appropriate optimizations ahead of time
	 * before the element is actually changed.
	 */
	will-change: transform;
}
