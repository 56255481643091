.o-diagonal-crop{

    --crop-value: 20px;

    @include md{

        --crop-value: 40px;
    
    }


    position: relative;
    z-index: 45;
    margin-top: calc( var(--crop-value) * -1);
    margin-bottom: calc( var(--crop-value) * -1);

    -webkit-clip-path: polygon( 
        0% calc( 0% + var(--crop-value) ),
        100% 0%,
        100% calc( 100% - var(--crop-value) ),
        0% 100%
    );

    clip-path: polygon( 
        0% calc( 0% + var(--crop-value) ),
        100% 0%,
        100% calc( 100% - var(--crop-value) ),
        0% 100%
    );
    
}
