.o-anim{

    &-fade-in{

        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    
        &.is-visible,
        .is-visible &{
            
            opacity: 1;
            visibility: visible;
    
        }

    }

    &-fi-up-scale{

        opacity: 0;
        visibility: hidden;
        transition: all 0.6s ease-in-out;
        transform: translateY(200px) scale(1.2);
    
        &.is-visible,
        .is-visible &{
            
            opacity: 1;
            visibility: visible;
            transform: translateY(0px) scale(1);
    
        }

    }

}