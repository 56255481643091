.o-settings{

    &-pt{
    
        @each $item in $spaces {
        
            &-#{$item}{
    
                &\@xs{
                    @include only-xs{
                        padding-top: #{$item}px;
                    }
                }
                &\@sm{
                    @include only-sm{
                        padding-top: #{$item}px;
                    }
                }
                &\@md{
                    @include only-md{
                        padding-top: #{$item}px;
                    }
                }
        
            }
    
        }
    
    }

    &-pb{
    
        @each $item in $spaces {
        
            &-#{$item}{
    
                &\@xs{
                    @include only-xs{
                        padding-bottom: #{$item}px;
                    }
                }
                &\@sm{
                    @include only-sm{
                        padding-bottom: #{$item}px;
                    }
                }
                &\@md{
                    @include only-md{
                        padding-bottom: #{$item}px;
                    }
                }
        
            }
    
        }
    
    }

}