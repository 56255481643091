.o-main{

    min-height: 100vh;
    padding-top: 49px;

    @include md{

        padding-top: 152px;

    }

}
