.o-font-display-1{

    @include rp-font($display-1);

}

.o-font-display-2{

    @include rp-font($display-2);

}

.o-font-display-3{

    @include rp-font($display-3);

}

.o-font-body{

    @include rp-font($body);

}

.o-font-caption{

    @include rp-font($caption);

}

