@import "../../bemit/bemit-settings-and-tools.scss";


.b-header{

    $b : &;

    // @include rp-prepare-debug();

    
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    z-index: 9999999;
    background-color: transparent;
    transition: all 0.2s ease-in-out 0.1s, transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    @include md{

        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: 99;
    
    }

    &__menu{

        display: block;
        width: 100%;
        height: auto;        
        
        @include md{

            display: flex;
            width: 100%;
            height: 100%;       

        }

    }

    &__menu-item{

        width: 100%;
        height: auto;
        position: relative;
        cursor: pointer;
        transition: all 0.6s ease-in-out;

        @include md{

            display: flex;  
            width: var(--menu-item-w); 
            
        }

        @include media-max($break-desktop){

            display: none;
    
        }

        &::before{
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: #dfdcd9;
            z-index: 1;
            transition: all 0.6s ease-in-out;
        }

        &::after{
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgba($black, 0.49);
            background: linear-gradient(to bottom, rgba($black, 0.2) 0%, rgba($black, 0) 20%);
            opacity: 0.5;
            z-index: 2;
            transition: all 0.6s ease-in-out;

            @include md{

                width: var(--menu-item-w); 
                background: linear-gradient(to right, rgba($black, 0.2) 0%, rgba($black, 0) 20%);
                
            }

        }

        &:first-child{

            width: 100%;
            padding: 20px 0px;

            @include md{

                width: var(--menu-item-w-first);
                padding: 0px;
                
            }

            @include media-max($break-desktop){

                display: block;
                padding: 31px 0px;
                padding-bottom: 34px;
        
            }

            @include media-max(430px){

                padding-bottom: 41px;

            }

            &::before{

                background-color: var(--color-black);

            }

            &::after{

                background-color: transparent;
                width: 100%;

                @include md{

                    width: var(--menu-item-w-first); 
                    
                }

            }
            
        }

        &:hover{

            @include md{
                &:nth-child(1){
                    #{$b}__menu-item-text{
                        // color: var(--color-a);
                    }
                }
    
                &:nth-child(2){
                    #{$b}__menu-item-text{
                        color: var(--color-b);
                    }
                }
    
                &:nth-child(3){
                    #{$b}__menu-item-text{
                        color: var(--color-c);
                    }
                }
    
                &:nth-child(4){
                    #{$b}__menu-item-text{
                        color: var(--color-d);
                    }
                }
    
                &:nth-child(5){
                    #{$b}__menu-item-text{
                        color: var(--color-e);
                    }
                }    
            }
            
        }

        &.is-active{

            width: 100%;

            @include md{

                width: calc(100% - ( (var(--menu-items) - 2) * var(--menu-item-w) ) - var(--menu-item-w-first) );
                
            }

            &::before{

                background-color: transparent;

            }

            &::after{

                opacity: 1;
                
            }

            &:first-child{

                width: 100%;

                @include md{

                    width: calc(100% - ( (var(--menu-items) - 1) * var(--menu-item-w) ) );
                    
                }

                &::before{

                    background-color: var(--color-black);

                }
                
            }

        }
        
    }

    &__menu-item-text{

        @include rp-font($display-1);

        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        color: var(--color-black);
        position: relative;
        z-index: 999;
        padding-left: 20px;
        padding-right: 20px;
        transition: all 0.6s ease-in-out, color 0.3s ease-in-out;

        @include md{

            position: absolute;
            transform: rotate(90deg) translateY(-100%);
            transform-origin: top left;
            padding-left: 40px;
            z-index: 3;
            top: 0px;
            left: 0px;
            height: var(--menu-item-w);
            padding-right: 0px;
            
        }

        .is-active &{

            color: var(--current-primary-color);

        }
        
    }

    &__menu-item:first-child &__menu-item-text,
    &__menu-item.is-active:first-child &__menu-item-text {

        color: var(--color-white);
        height: auto;

        @include md{

            height: var(--menu-item-w-first);
            white-space: nowrap;

        }

        @include media-max($break-desktop){

            justify-content: center;
            text-align: center;
    
        }

    }

    &__menu-buttons{

        width: var(--menu-item-w-first);
        display: block;
        color: var(--color-black);
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 5;
        transition: all 0.6s ease-in-out;
        padding: 20px 15px;

        @include md{

            top: auto;
            right: auto;
            bottom: 0px;
            left: 0px;
            
        }

        @include media-max($break-desktop){

            display: none;
    
        }

    }

    &__button{

        width: 100%;
        display: flex;
        height: 35px;
        margin-bottom: 0px;

    }

    &__language-picker {

        width: 100%;
        height: 35px;
        text-align: center;
        color: white;

    }

    &__language-link {

        color: white;
        padding: 0 10px;

        &.is-active {

            font-weight: bold;

        }

    }

    &__iframe{

        width: 100%;
        display: block;
        border: 0px;

    }

    &__menu-item-label-date{

        $current_font: (
            md: (
                font-size       : 14px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 11px,
                line-height     : 15px,
                letter-spacing  : 0.4px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 11px,
                line-height     : 15px,
                letter-spacing  : 0.4px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        width: 100%;
        height: auto;
        display: block;
        color: var(--color-white);
        position: absolute;
        left: 0px;
        z-index: 3;
        transition: all 0.6s ease-in-out;
        text-transform: uppercase;
        padding-left: 20px;
        padding-right: 20px;
        top: -17px;
        text-align: center;

        @include md{

            top: 18px;
            padding-left: 40px;
            padding-right: 0px;

        }

    }

    &__menu-item-label-info{

        $current_font: (
            md: (
                font-size       : 14px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 11px,
                line-height     : 15px,
                letter-spacing  : 0.4px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 11px,
                line-height     : 15px,
                letter-spacing  : 0.4px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        width: 100%;
        height: auto;
        display: block;
        color: var(--color-white);
        position: absolute;
        left: 0px;
        z-index: 3;
        transition: all 0.6s ease-in-out;
        text-transform: uppercase;
        padding-left: 20px;
        padding-right: 20px;
        bottom: -18px;
        text-align: center;

        @include media-max(430px){

            bottom: -28px;

        }

        @include md{

            bottom: 23px;
            padding-left: 40px;
            padding-right: 0px;

        }

    }

    &__wrapper-logo-partner{

        display: block;
        width: 100%;
        padding-top: 10px;

    }
    
}
