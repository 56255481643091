// =========================================================================================
// #TAGS
// =========================================================================================

html,
body{

    @include rp-font($body);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    height: auto;
    background-color: $white;

    @include md{

        overflow: hidden;

    }

    &::-webkit-scrollbar {

        width: 8px;
        height: 8px;

    }

    &::-webkit-scrollbar-button {

        width: 1px;
        height: 1px;

    }

    &::-webkit-scrollbar-thumb {

        background: var(--current-primary-color);
        border: 1px solid var(--current-primary-color);
        border-radius: 0px;

    }

    &::-webkit-scrollbar-track {

        background: transparent;
        border: 1px solid transparent;
        border-radius: 0px;

    }

    &::-webkit-scrollbar-corner {

        background: transparent;

    }

}

body{

    // @include rp-custom-scrollbar(
    //     $scrollbar__size,
    //     $scrollbar-button__size,
    //     $scrollbar-track__bg,
    //     $scrollbar-track__border,
    //     $scrollbar-track__border-radius,
    //     $scrollbar-thumb__bg,
    //     $scrollbar-thumb__border,
    //     $scrollbar-thumb__border-radius
    // );

    &.is-debug{

        @include rp-debug-this();

    }

    &.is-overflow-hidden{

        overflow: hidden;

    }

    // cursor: url('../../assets/img/cursor.png'), auto;

}

ul{
    
    list-style-type: none;
    padding: 0;
    margin: 0;

}

img{

    max-width: 100%;
    width: auto;
    height: auto;
    display: block;

}

h1, h2, h3, h4, h5, h6 {
    
    color: $black;
    padding: 0px;
    margin: 0px;
    text-transform: none;

}

p{

    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    padding: 0;
    padding-bottom: var(--padding-bottom-p);
    margin: 0;
    font-size: inherit;
    line-height: inherit;

    &:last-child{

        padding-bottom: 0px;

    }

    &:empty{

        display: none;

    }

}

a{

    color: inherit;
    // transition: $rp_transition;
    text-decoration: none;
    cursor: pointer;

    &:hover{

        color: $color-a;

    }

}

// =========================================================================================
// #END TAGS
// =========================================================================================