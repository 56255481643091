
// =========================================================================================
// #EXTERNAL - PLUGINS WP
// =========================================================================================

.ril__toolbar{

    background-color: transparent!important;

}

.ril__zoomInButton,
.ril__zoomOutButton{

    display: none;

}

// =========================================================================================
// #END EXTERNAL
// =========================================================================================