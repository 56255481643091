@import "../../bemit/bemit-settings-and-tools.scss";

.c-info{
    
    $b : &;

    $icon_w: 100px;
    
    @include rp-prepare-debug();

    padding-bottom: 40px;
    display: block;

    &__top{

        display: flex;
        width: 100%;
        align-items: flex-start;

    }

    &__wrapper-title{

        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 12px;

    }

    &__title{

        @include rp-font($display-2);

        display: block;
        width: auto;
        color: var(--current-primary-color);
        text-transform: uppercase;
        padding-right: 8px;

    }

    &__date{

        @include rp-font($body);

        display: inline-flex;
        align-items: center;
        width: auto;
        color: var(--current-primary-color);
        padding-bottom: 5px;

    }

    &__body{

        display: block;
        padding-top: 20px;
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--current-primary-color);

        @include sm{

            display: flex;
            padding-top: 0px;

        }

        @include md{

            padding-right: 10%;

        }

    }

    &__wrapper-image{

        display: block;
        width: 100%;
        max-width: 100%;

        @include sm{

            width: $icon_w;

        }

    }

    &__image{

        display: block;
        width: 100px;
        max-width: 100%;
        height: auto;

    }

    &__wrapper-content{

        display: flex;
        align-items: flex-end;
        width: 100%;
        padding-top: 30px;

        @include sm{

            width: calc(100% - #{$icon_w});
            padding-left: 20px;
            padding-top: 0px;

        }

        &:first-child{

            padding-left: 0px;
            padding-right: 32px;
            width: 100%;
            
        }

    }

    &__the-content{

        $current-font: (
            md: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__italic),
                font-weight     : normal
            ),
            sm: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__italic),
                font-weight     : normal
            ),
            xs: (
                font-size       : 16px,
                line-height     : 20px,
                letter-spacing  : 0px,
                font-family     : var(--font-b__italic),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        display: block;
        width: 100%;
        color: $black;

    }

}
