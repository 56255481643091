@import "../../bemit/bemit-settings-and-tools.scss";

.b-not-found{

    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    z-index: 9999997;
    background-color: $black;

    &__text{

        @include rp-font($display-1);

        color: $white;
        
    }

    &__wrapper-button{

        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 20px;

    }

    &__button{

        @include rp-font($body);

        background-color: transparent;
        color: $white;
        display: inline-block;
        border-radius: 10px;
        padding: 12px 32px;
        border: 1px solid $white;
        transition: all 0.3s ease-in-out;

        &:hover{
            
            background-color: $white;
            color: $black;

        }

    }

}
