@import "../../bemit/bemit-settings-and-tools.scss";

.c-grid{
    
    $b : &;
    $row_gap : 105px;
    $items_per_row: 5;
    $item_padding_left: 20px;
    
    @include rp-prepare-debug();

    padding: 20px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-column-gap: 0px;
    grid-row-gap: $row_gap;

    @include media-max($break-tablet){

        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));

    }

    &__item{

        position: relative;
        width: auto;
        display: block;
        grid-column-end: span 1;
        grid-row-end: span 1;
        padding: 0px $item_padding_left;
        cursor: pointer;

        &::before{

            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 1px;
            height: 100%;
            background-color: var(--current-primary-color);
            transition: background-color 0.1s ease-in-out 0.2s;

        }

        &:nth-child(#{$items_per_row}n + 1){

            @include media-min($break-tablet + 1){

                &::before{

                    width: calc( (100%*#{$items_per_row}));
                    left: 0px;
                    height: 1px;
                    top: calc(0px - (#{$row_gap}/2)); // BORDER ON TOP
    
                }

            }
            
        }

        &:first-child{
            &::before{
                display: none; // FOR BORDER TOP
            }
        }

        @include media-max($break-tablet){

            &:nth-child(2n + 1){

                &::before{
    
                    width: calc( (100%*2));
                    left: 0px;
                    height: 1px;
                    top: calc(0px - (#{$row_gap}/2)); // BORDER ON TOP
    
                }
                
            }

        }
        
    }

    &__wrapper-image{

        display: block;
        width: 100%;
        max-width: 100%;
        padding-bottom: 10px;
        position: relative;

    }

    &__image{

        display: block;
        width: auto;
        height: 72px;
        margin: 0 auto;
        max-width: 100%;
        position: relative;
        z-index: 1;
        transition: all 0.2s ease-in-out;

        @include media-max($break-tablet){

            height: 110px;

        }

        &--hover{

            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            transition: all 0.2s ease-in-out;

        }

    }

    &__item:hover &__image{

        @include md{

            opacity: 0;
            visibility: hidden;

        }

    }

    &__item:hover &__image--hover{

        @include md{

            opacity: 1;
            visibility: visible;

        }

    }

    &__wrapper-title{

        display: block;
        width: 180px;
        max-width: 100%;
        margin: 0 auto;

        @include media-max($break-tablet){

            width: 100%;
            margin: 0px;
            // padding: 0px 14px;

        }

    }

    &__title{

        $current_font: (
            md: (
                font-size       : 12px,
                line-height     : 14px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            sm: (
                font-size       : 12px,
                line-height     : 14px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
            xs: (
                font-size       : 12px,
                line-height     : 14px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-a__medium),
                font-weight     : normal
            ),
        );

        @include rp-font($current_font);

        display: block;
        width: 100%;
        color: var(--color-black);
        text-transform: uppercase;
        text-align: center;

        @include media-max($break-tablet){

            word-break: break-word;

        }

    }

}
