.o-color{
    
    &-black{
        
        color: $black;

    }

    &-white{
        
        color: $white;
        
    }

}
