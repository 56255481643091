.u-none{

    display: none!important;
    
    &\@xs{
        @include only-xs{
            display: none!important;
        }
    }
    &\@sm{
        @include only-sm{
            display: none!important;
        }
    }
    &\@md{
        @include only-md{
            display: none!important;
        }
    }
}

.u-block{

    display: block!important;

}

