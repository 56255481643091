@import "../../bemit/bemit-settings-and-tools.scss";


.b-footer{

    $b : &;

    @include rp-prepare-debug();

}
