:root{

    --grid-gutter-width: #{$grid-gutter-width__md};
    --total-columns: #{$total-columns__md};
    --container-padding: #{$container-padding__md};
    --container-width: #{$container__width};
    --padding-bottom-p: #{$padding-bottom-p};
    --header-height: #{$header-height__md};
    --font-a__medium: #{quote($font-a__medium)};
    --font-a__bold: #{quote($font-a__bold)};
    --font-a__black: #{quote($font-a__black)};
    --font-b__regular: #{quote($font-b__regular)};
    --font-b__italic: #{quote($font-b__italic)};
    --font-b__bold: #{quote($font-b__bold)};
    --font-b__bold-italic: #{quote($font-b__bold-italic)};
    --color-black: #{$black};
    --color-white: #{$white};
    --color-a: #{$color-a};
    --color-b: #{$color-b};
    --color-c: #{$color-c};
    --color-d: #{$color-d};
    --color-e: #{$color-e};
    --color-a-alt: #{$color-a-alt};
    --color-b-alt: #{$color-b-alt};
    --color-c-alt: #{$color-c-alt};
    --color-d-alt: #{$color-d-alt};
    --color-e-alt: #{$color-e-alt};


    --menu-item-w: 140px;
    --menu-item-w-first: 175px;
    

    --w-thin: #{$w-thin};
    --w-xlight: #{$w-xlight};
    --w-light: #{$w-light};
    --w-regular: #{$w-regular};
    --w-medium: #{$w-medium};
    --w-sbold: #{$w-sbold};
    --w-bold: #{$w-bold};
    --w-sblack: #{$w-sblack};
    --w-black: #{$w-black};

    --s-normal : #{$s-normal};
    --s-italic : #{$s-italic};
    --s-oblique: #{$s-oblique};

    @include only-sm{

        --grid-gutter-width : #{$grid-gutter-width__sm};
        --total-columns: #{$total-columns__sm};
        --container-padding: #{$container-padding__sm};
        --header-height: #{$header-height__sm};

    }

    @include only-xs{

        --grid-gutter-width : #{$grid-gutter-width__xs};
        --total-columns: #{$total-columns__xs};
        --container-padding: #{$container-padding__xs};
        --header-height: #{$header-height__xs};

    }

}

*,
*:after,
*:before {

    padding: 0;
    margin: 0;
    outline: 0px solid;
    box-sizing: border-box;

    &:hover,
    &:active,
    &:focus, 
    &:visited{

        outline: 0px solid;

    }

}

::selection{

    color: $white; 
    background-color: var(--current-primary-color);

}

#rp-page{

    position: relative;
    z-index: 3;
    background-color: transparent;

}

.rp-main{

    z-index: 2;

}

.scrollbar-track{

    background-color: transparent!important;

}

.scrollbar-thumb{

    background-color: var(--current-primary-color)!important;
    width: 4px!important;
    left: auto!important;
    right: 0px!important;
    border-radius: 0px!important;

}

.cookies{

    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: 99999999999;
    background-color: var(--color-white);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    padding: 25px 20px;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.78px;
    font-family: var(--font-a__medium);

    &.is-open{

        opacity: 1;
        visibility: visible;

    }

    &__container{

        margin: 0 auto;
        max-width: 100%;
        width: 720px;

    }

    &__btn{

        display: inline-block;
        margin: 0px 10px;
        cursor: pointer;

    }

    &__link{

        color: $black;
        text-decoration: underline;
        transition: all 0.3s ease-in-out;
        display: inline-block;
        font-family: var(--font-a__bold);

        &:hover{

            color: $black;

        }

    }

    &__accept{

        border-radius: 20px;

    }

    &__accept-text{

        color: $black;
        text-decoration: underline;
        transition: all 0.3s ease-in-out;
        display: inline-block;
        font-family: var(--font-a__bold);
        text-transform: uppercase;
        background-color: #02FFA6;
        padding: 10px 40px;
        border-radius: 20px;
        text-decoration: none;

        &:hover{

            color: $white;
            background-color: $black;

        }

    }

}
