.o-bg-color{

    &-black{

        background-color: $black;

    }

    &-white{

        background-color: $white;

    }

}
