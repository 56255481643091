@import "../../bemit/bemit-settings-and-tools.scss";

.c-select{
    
    $b : &;
    
    @include rp-prepare-debug();

    padding-bottom: 0px;
    top: 20px;
    display: block;
    position: absolute;
    z-index: 9;

    @include md{

        top: 46px;

    }

}
