@import "../../bemit/bemit-settings-and-tools.scss";

.c-medias{
    
    $b : &;
    
    @include rp-prepare-debug();

    padding-bottom: 23px;
    display: flex;
    align-items: flex-start;

    @include media-max($break-tablet){

        display: block;

    }

    &__first-col{

        width: 65%;
        padding-right: 20px;

        @include media-max($break-tablet){

            width: 100%;
            padding-right: 0px;
    
        }

    }

    &__second-col{

        width: 35%;

        @include media-max($break-tablet){

            width: 100%;
    
        }

    }

    &__item{

        position: relative;
        width: 100%;
        display: block;
        padding-bottom: 26px;

        &--alt{
            
            border-bottom: 1px solid var(--current-primary-color);
            padding-bottom: 20px;
            margin-bottom: 21px;

        }

    }

    &__wrapper-image{

        display: block;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
        background-color: var(--current-primary-color);

        @include rp-aspect-ratio();

        img{
            
            display: block;
            width: 100%;
            height: auto;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            object-fit: cover;
            z-index: 2;

        }

        .lazy-load-image-background.opacity{
            
            background-image: none !important;
            opacity: 0;
            transition: all 0.4s ease-in-out;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: var(--current-primary-color);

        }

        .lazy-load-image-background.opacity.lazy-load-image-loaded {

            opacity: 1;

        }

    }

    &__placeholder{

        display: block;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        background-color: var(--current-primary-color);
        z-index: 1;

    }

    &__wrapper-title{

        display: block;
        width: 100%;
        padding-right: 20px;
        padding-bottom: 12px;

    }

    &__title{

        @include rp-font($display-3);

        display: block;
        width: 100%;
        color: $black;

    }

    &__wrapper-info{

        width: 100%;
        display: block;

    }

    &__info-text{

        $current-font: (
            md: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__bold),
                font-weight     : normal
            ),
            sm: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__bold),
                font-weight     : normal
            ),
            xs: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__bold),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        color: var(--current-primary-color);
        text-transform: uppercase;
        display: inline;

        &-highlight{

            .is-home &{

                $current-font: (
                    md: (
                        font-size       : 14px,
                        line-height     : 18px,
                        letter-spacing  : 0.5px,
                        font-family     : var(--font-b__regular),
                        font-weight     : normal
                    ),
                    sm: (
                        font-size       : 14px,
                        line-height     : 18px,
                        letter-spacing  : 0.5px,
                        font-family     : var(--font-b__regular),
                        font-weight     : normal
                    ),
                    xs: (
                        font-size       : 14px,
                        line-height     : 18px,
                        letter-spacing  : 0.5px,
                        font-family     : var(--font-b__regular),
                        font-weight     : normal
                    ),
                );

                @include rp-font($current-font);

                background-color: var(--current-primary-color);
                color: var(--color-white);

            }

        }

    }

    &__item--alt &__info-text{

        color: var(--current-primary-color);

        .is-home &{

            color: var(--current-primary-color);

        }

        &-highlight{

            .is-home &{

                background-color: var(--current-primary-color);
                color: var(--color-white);

            }

        }

    }

    &__info-sep{

        $current-font: (
            md: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            sm: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
            xs: (
                font-size       : 14px,
                line-height     : 18px,
                letter-spacing  : 0.5px,
                font-family     : var(--font-b__regular),
                font-weight     : normal
            ),
        );

        @include rp-font($current-font);

        color: var(--current-primary-color);
        text-transform: uppercase;
        padding-left: 4px;
        display: inline;

    }

    &__item--alt &__info-sep{

        color: var(--current-primary-color);

    }

}
