.o-page-transition{

    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 999999;
    height: auto;
    padding: 32px 16px;


    @include md{

        position: fixed;
        overflow: auto;
        left: calc( ( ( (var(--menu-item-children-active) - 1) * var(--menu-item-w) ) + (var(--menu-item-w-first) ) ) );
        width: calc(100% - (var(--menu-item-w) * (var(--menu-items) - 1) ) - ( var(--menu-item-w-first) ) );
        height: 100vh;
        padding-top: 32px;
        padding-right: 23px;
        padding-bottom: 32px;

        &::-webkit-scrollbar {

            width: 8px;
            height: 8px;

        }

        &::-webkit-scrollbar-button {

            width: 1px;
            height: 1px;

        }

        &::-webkit-scrollbar-thumb {

            background: var(--current-primary-color);
            border: 1px solid var(--current-primary-color);
            border-radius: 0px;

        }

        &::-webkit-scrollbar-track {

            background: transparent;
            border: 1px solid transparent;
            border-radius: 0px;

        }

        &::-webkit-scrollbar-corner {

            background: transparent;

        }

        &.is-mapa{

            padding-bottom: 0px;

        }

    }
    
    &__inner{

        border-radius: 10px;
        background-color: var(--color-white);

        @include md{

            padding: 10px 0px;
            
            .is-home &{

                padding: 20px 25px;

            }

            .is-mapa &{

                padding-bottom: 0px;
        
            }

        }
        
    }
    
}
